


















import { gsap } from 'gsap'
import Vue from 'vue'

export default Vue.extend({
  name: 'LayoutDefault',

  methods: {
    viewBeforeEnter(el: HTMLElement): void {
      gsap.set(el, {
        opacity: 0,
      })
    },

    viewEnter(el: HTMLElement, done: () => void): void {
      gsap.to(el, {
        duration: 0.3,
        opacity: 1,
        ease: 'linear',
        onComplete: done,
      })
    },

    viewLeave(el: HTMLElement, done: () => void): void {
      gsap.to(el, {
        duration: 0.3,
        opacity: 0,
        ease: 'linear',
        onComplete: done,
      })
    },
  },
})
